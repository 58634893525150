import React from 'react'
import { Tag } from 'antd'
// import { useIntl } from 'react-intl'
import moment from 'moment'
import capitalize from 'lodash/capitalize'

import IntlMessages from '../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { DisplayLeaveDuration, displayToilInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'

const columnsIndex = {
  title: '#',
  dataIndex: 'id',
  key: 'id',
  render: (date, row, index: number) => {
    return (index + 1)
  },
}

const columnsStartDate = (locale: LocaleEnum) => ({
  title: <IntlMessages id="components.leavesColumns.dates" />,
  dataIndex: 'startDate',
  key: 'startDate',
  // eslint-disable-next-line react/display-name
  render: (startDate) => {
    return (<>
      <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
    </>)
  },
})

const columnsStartEndDate = (locale: LocaleEnum, hourFormat: HourFormatEnum) => ({
  title: <IntlMessages id="components.leavesColumns.dates" />,
  dataIndex: 'startDate',
  key: 'startDate',
  // eslint-disable-next-line react/display-name
  render: (startDate: string, row) => {
    let formattedDate = <>
      <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} /> - <FormattedDate value={row.endDate} format="MMMM Do YYYY." locale={locale} />
    </>
    if (moment(startDate).format('YYYY-MM-DD') === moment(row.endDate as string).format('YYYY-MM-DD') && row.isPartDay) {
      const startHour = convertHourFormats(hourFormat, row.partDayStartHour)?.value
      const startHourAmOrPm = convertHourFormats(hourFormat, row.partDayStartHour)?.amOrPm
      const endHour = convertHourFormats(hourFormat, row.partDayEndHour)?.value
      const endHourAmOrPm = convertHourFormats(hourFormat, row.partDayEndHour)?.amOrPm

      formattedDate = <><FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
      ({startHour}{startHourAmOrPm} - {endHour}{endHourAmOrPm})
      </>
    }
    if (moment(startDate).format('YYYY-MM-DD') === moment(row.endDate as string).format('YYYY-MM-DD') && !row.isPartDay) {
      formattedDate = <FormattedDate value={startDate} format="MMMM Do YYYY." locale={locale} />
    }

    return <>
      {formattedDate} {row.isEdited && <Tag color="#7f00ff"><IntlMessages id="app.edited" /></Tag>}
    </>
  },
})

const columnsLeaveTypeName = {
  title: <IntlMessages id="app.leaveType" />,
  dataIndex: 'leaveType',
  key: 'leaveType',
  render: (_, row) => {
    const leaveType = row.leavePolicy?.leaveType || row.leaveType

    if (row.isPartDay && !row.hourlyLeaveAccounting) {
      return <IntlMessages id='components.leaveForm.leaveTypeNamePartTime' values={{ leaveTypeName: leaveType.name }} />
    }
    return leaveType.name
  },
}

const columnsDuration = (formatMessage) => ({
  title: <IntlMessages id="components.leavesColumns.duration" />,
  dataIndex: 'workingDays',
  key: 'workingDays',
  width: 90,
  render: (workingDays, row) => {
    return isToilLeave(row.id)
      ? <>{displayToilInDaysAndHours(formatMessage, row)}</>
      : <DisplayLeaveDuration daysList={row.daysList} value={workingDays} />
  },
})

const columnsReason = {
  title: <IntlMessages id="components.leavesColumns.reason" />,
  dataIndex: 'reason',
  key: 'reason',
  render: (reason) => {
    return <div dangerouslySetInnerHTML={{__html: reason}}></div>
  },
}

const columnsDenyReason = {
  title: <IntlMessages id="components.leavesColumns.denyReason" />,
  dataIndex: 'statusReason',
  key: 'statusReason',
  render: (statusReason) => {
    return <div dangerouslySetInnerHTML={{__html: statusReason}}></div>
  },
}

const columnsStatus = (formatMessage) => ({
  title: <IntlMessages id="app.status" />,
  dataIndex: 'status',
  key: 'status',
  // eslint-disable-next-line react/display-name
  render: (status) => {
    let statusColor = ''
    let statusText = ''
    if (status === 'APPROVED') {
      statusColor = 'green'
      statusText = capitalize(formatMessage({ id: 'app.approved' }))
    } else if (status === 'DENIED') {
      statusColor = 'red'
      statusText = capitalize(formatMessage({ id: 'app.denied' }))
    } else if (status === 'CANCELLED') {
      statusColor = 'orange'
      statusText = capitalize(formatMessage({ id: 'app.cancelled' }))
    } else if (status === 'EXPIRED') {
      statusColor = 'orange'
      statusText = capitalize(formatMessage({ id: 'app.expired' }))
    } else if (status === 'DELETED') {
      statusColor = 'magenta'
      statusText = capitalize(formatMessage({ id: 'app.deleted' }))
    } else {
      statusColor = 'black'
      statusText = capitalize(formatMessage({ id: 'app.expired' }))
    }
    return (
      <Tag color={statusColor}>{statusText}</Tag>
    )
  },
})

const columnsApprover = {
  title: <IntlMessages id="components.leavesColumns.reviewedby" />,
  dataIndex: 'approver',
  key: 'approver',
  render: (approver, row) => {
    if (row.autoApproved) {
      return <IntlMessages id="app.autoApproved" />
    } else if (row.cancelledBeforeReview) {
      return <IntlMessages id="components.leavesColumns.cancelledBeforeReview" />
    } else {
      return row.approvedBySubstituteApprover ? <>{approver?.name}{' ('}<IntlMessages id="app.substituteApprover" />{')'}</> : approver?.name
    }
  },
}

export {
  columnsIndex,
  columnsStartDate,
  columnsStartEndDate,
  columnsLeaveTypeName,
  columnsDuration,
  columnsReason,
  columnsDenyReason,
  columnsStatus,
  columnsApprover
}
