import Analytics, { AnalyticsInstance } from 'analytics'
import amplitudePlugin from '@analytics/amplitude'
import googleTagManager from '@analytics/google-tag-manager'
const VERSION = '2.0'

type EventPayload = {
  [key: string]: string | number | boolean
}

const gtmContainerId = process.env.REACT_APP_GTM_CONTAINER_ID || ''
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || ''

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production'

let analytics: AnalyticsInstance | null = null

if (IS_PRODUCTION) {
  analytics = Analytics({
    app: 'vacation-tracker',
    plugins: [
      amplitudePlugin({
        apiKey: amplitudeApiKey,
        options: {
          includeUtm: true,
          trackingOptions: {
            ip_address: false,
          },
        },
      }),
      googleTagManager({
        containerId: gtmContainerId,
      }),
    ],
  })
}

export async function page(url?: string): Promise<void> {
  if (IS_PRODUCTION && analytics) {
    try {
      const payload: EventPayload = {}
      if (url) {
        payload.url = `https://vacationtracker.io/v2${url}`
      }
      await analytics.page(payload)
    } catch (err) {
      // Ignore analytics errors
    }
  } else {
    return Promise.resolve()
  }
}

export async function track(eventName: string, payload: EventPayload): Promise<void> {
  if (IS_PRODUCTION && analytics) {
    try {
      await analytics.track(eventName, {
        ...payload,
        version: VERSION,
      })
    } catch (err) {
      // Ignore analytics errors
    }
  } else {
    return Promise.resolve()
  }
}

export async function identify(userId: string, traits: EventPayload): Promise<void> {
  if (IS_PRODUCTION && analytics) {
    try {
      const params = {
        ...traits,
      }
      if (traits.email && typeof traits.email === 'string') {
        params.email = traits.email.toLowerCase()
      }
      await analytics.identify(userId, {
        ...params,
        version: VERSION,
      })
    } catch (err) {
      // Ignore analytics errors
    }
  } else {
    return Promise.resolve()
  }
}
