import React from 'react'
import { Button, Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import IntlMessages from '../../util/IntlMessages'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { getSafeLocale } from '@vacationtracker/shared/i18n'

interface IUserTodayOff {
  todayOff: any
  handleCancelLeave?: Function
  locale: LocaleEnum
}

const UserTodayOff: React.SFC<IUserTodayOff> = ({ todayOff, handleCancelLeave, locale }) => {
  const safeLocale = getSafeLocale(locale)
  return (
    <Alert type="success"
      icon={<InfoCircleOutlined />}
      showIcon
      style={{ marginBottom: 20 }}
      message={<IntlMessages id="components.userTodayOff.leaveInfo" />}
      description={
        <>
          <div className="user-offtoday">
            {dayjs(todayOff.startDate as string).locale(safeLocale).format('YYYY-MM-DD') === dayjs(todayOff.endDate as string).locale(safeLocale).format('YYYY-MM-DD') ?
              <>
                {
                  todayOff.isPartDay ?
                    <IntlMessages id="components.userTodayOff.offTodayPartDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      from: todayOff.partDayStartHour,
                      to: todayOff.partDayEndHour,
                    }} /> :
                    <IntlMessages id="components.userTodayOff.offTodayFullDay" values={{
                      leaveTypeName: todayOff.leaveType.name,
                      today: dayjs(todayOff.startDate as string).locale(safeLocale).format('MMMM D, yyyy'),
                    }} />
                }
              </> :
              <IntlMessages id="components.userTodayOff.offFromTo" values={{
                leaveTypeName: todayOff.leaveType.name,
                startDate: dayjs(todayOff.startDate as string).locale(safeLocale).format('MMMM D, yyyy'),
                endDate: dayjs(todayOff.endDate as string).locale(safeLocale).format('MMMM D, yyyy'),
              }} />
            }
            {handleCancelLeave &&
              <Button onClick={() => handleCancelLeave(todayOff, 'today')} danger
                style={{ float: 'right', position: 'relative', top: -15 }}>
                <IntlMessages id="app.cancelLeave" />
              </Button>
            }
          </div>
          {todayOff.reason &&
            <p className="user-offtoday-reason">
              <IntlMessages id="components.userTodayOff.offTodayReason" values={{ reason: todayOff.reason }} />
            </p>
          }
        </>
      }
    />
  )
}

export default UserTodayOff
