import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/pl'
import 'dayjs/locale/tr'
import NextApp from './NextApp'
import * as serviceWorker from './serviceWorker'
import { AppProvider } from './appContext/store'
import { store } from './store/configure'
import './index.css'

if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_VERSION,
  })
}

const app = (
  <AppProvider>
    <Provider store={store}>
      <NextApp />
    </Provider>
  </AppProvider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
